import cloneDeep from 'lodash.clonedeep'

import { fetchProductsForCarouselConfigs, carouselRecordType } from '~/lib/fetch-products-for-carousel-configs'
import { fetchProductsForProductsViewConfigs, productsViewRecordType } from '~/lib/fetch-products-for-products-view-configs'
import { transformProductBundles } from '~/lib/transform-product-bundles'

const categoryUrlPathStorage = new Map()

/*
 * Configs for these components contains only SKU's for products.
 * Since we need actual product data, the SKU's are replaced wth product data.
 */
export default async function (context, pageData) {
  await recursiveLookForContentItem(pageData, context, 'SmartLinkRecord', getCategoryUrlPath)

  const categoryMappings = context.$staticDataService.categoryMappingsIndex
  const carouselsConfigs = pageData.content.filter(item => {
    if (item.productCarousel?.category) {
      // We check category mappings json to check if the category is active for this environment
      // to prevent Elastic timeouts
      return item.__typename === carouselRecordType && categoryMappings[item.productCarousel.categoryId]
    }

    return item.__typename === carouselRecordType
  })

  await fetchProductsForCarouselConfigs(context.app.$productApiService, carouselsConfigs)

  // We check category mappings json to check if the category is active for this environment
  // to prevent Elastic timeouts
  const productsViews = pageData.content.filter(item => item.__typename === productsViewRecordType && categoryMappings[item.categoryId])

  await fetchProductsForProductsViewConfigs(context.app.$productApiService, productsViews)

  // Product bundles
  if (pageData.productBundles) {
    // Elastic products are fetched for featured content
    await transformProductBundles(context, pageData.productBundles)
  }

  return Promise.resolve(cloneDeep(pageData))
}

/**
 * Campaign pages frequently have links to the same
 * category pages. This is why there is storage in place
 * to prevent unnecessary elastic calls.
 */
async function getCategoryUrlPath(context, content) {
  if (!content.categoryId?.length) {
    return Promise.resolve()
  }

  const currentLocale = context.app.i18n.locale
  const categoryUrlPathStorageKey = `${currentLocale}-${content.categoryId}`

  if (categoryUrlPathStorage.get(categoryUrlPathStorageKey)) {
    content.categoryUrlPath = categoryUrlPathStorage.get(categoryUrlPathStorageKey)
    return Promise.resolve()
  }

  content.categoryUrlPath =
    await context.app.$categoryApiService
      .getCategoryById(content.categoryId, ['url_path'])
      .then(category => category?.url_path?.[0])
      .catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(error)
        }

        return '404'
      })

  categoryUrlPathStorage.set(categoryUrlPathStorageKey, content.categoryUrlPath)

  return Promise.resolve()
}

/**
 * This function looks for content with a specific typename recursively (such as 'SmartLinkRecord').
 * When this content is found, a callback is called which is provided.
 * @param {*} content
 * @param {object} context
 * @param {string} typename
 * @param {function} asyncCallback
 * @return {Promise}
 */
async function recursiveLookForContentItem(content, context, typename, asyncCallback) {
  if (Array.isArray(content)) {
    // We want to resolve promises sequentially, so we can use storage
    // to limit api calls. Otherwise, with Promise.all all promises are
    // fired at once.
    for (const item in content) {
      if (!content.hasOwnProperty(item)) {
        return
      }

      await recursiveLookForContentItem(content[item], context, typename, asyncCallback)
    }
    return
  }

  if (
    typeof content === 'object'
    && content !== null
    && content.__typename !== typename
  ) {
    const promises = []

    Object.keys(content).forEach(key => promises.push(recursiveLookForContentItem(content[key], context, typename, asyncCallback)))
    await Promise.all(promises)
    return
  }

  if (typeof content === 'object' && content !== null) {
    await asyncCallback(context, content)
  }
}
