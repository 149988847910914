const baseUrl = process.env.baseUrl
export const twitterName = '@loavies'
export const siteName = 'loavies.com'
export const defaultTitle = 'LOAVIES'
export const defaultDescription = 'Loavies website – Shop the latest women’s clothing'
export const defaultShareImage = {
  path: '/logos/loavies-logo.png',
  width: 404,
  height: 68,
}

// Use this function directly in a nuxt page
export default function (pageSeo, route) {
  const pageTitle = pageSeo?.title ?? defaultTitle
  const metaTitle = pageSeo?.metaTitle ?? pageTitle
  const shareImage = pageSeo?.image ?? Object.assign(defaultShareImage, {
    url: `${baseUrl}${defaultShareImage.path}`,
  })
  const description = pageSeo?.description ?? defaultDescription

  return {
    title: pageTitle,
    meta: [
      { hid: 'description', name: 'description', content: description },
      /* Facebook */
      { hid: 'og:url', property: 'og:url', content: `${baseUrl}${route.path}` },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:title', property: 'og:title', content: metaTitle },
      { hid: 'og:description', property: 'og:description', content: description },
      { hid: 'og:image', property: 'og:image', content:  shareImage.url },
      { hid: 'og:image:width', property: 'og:image:width', content: shareImage.width },
      { hid: 'og:image:height', property: 'og:image:height', content: shareImage.height },
      { hid: 'og:site_name', property: 'og:site_name', content: siteName },
      /* Twitter */
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:creator', name: 'twitter:creator', content: twitterName },
      { hid: 'twitter:title', name: 'twitter:title', content: metaTitle },
      { hid: 'twitter:description', name: 'twitter:description', content: description },
      { hid: 'twitter:image', name: 'twitter:image', content: shareImage.url },
    ],
  }
}
