
  import cookie from '~/lib/cookie'
  import {
    AUTH_FLY_IN_NAME,
    CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME,
  } from '~/lib/constants'

  export default {
    props: {
      page: {
        type: Object,
        required: true,
        validator(page) {
          return (
            typeof(page) === 'object'
            && typeof(page.title) === 'string'
            && Array.isArray(page.content)
          )
        },
      },
    },
    watch: {
      page: {
        immediate: true,
        handler() {
          this.setCustomAuthFlyInCookie()
        },
      },
    },
    mounted() {
      this.checkForLiveShoppingEvent()
    },
    methods: {
      checkForLiveShoppingEvent() {
        if (this.page.autoplayLiveShopping && this.page.liveShoppingItem) {
          this.$bambuserService.initializeLiveShopping(this.page.liveShoppingItem.showId)
        }
      },
      setCustomAuthFlyInCookie() {
        if (this.page.customAuthFlyIn) {
          cookie(
            CUSTOM_AUTH_FLY_IN_TYPE_COOKIE_NAME,
            JSON.stringify({
              name: AUTH_FLY_IN_NAME,
              type: this.page.customAuthFlyIn.queryParameter,
              ...(this.page.customAuthFlyIn.mode && { mode: this.page.customAuthFlyIn.mode }),
            }),
          )
        }
      },
    },
  }
