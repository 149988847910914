import { render, staticRenderFns } from "./campaign-page.vue?vue&type=template&id=65a3b110"
import script from "./campaign-page.vue?vue&type=script&lang=js"
export * from "./campaign-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModularContent: require('/opt/build/repo/src/client/components/modular-content/modular-content.vue').default})
