import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'
import productCardAttributes from '~/components/product-card/product-card-attributes'
import { carouselRecordType, fetchProductsForCarousel } from '~/lib/fetch-products-for-carousel-configs'

export const alternativeProductBlockRecord = 'AlternativeProductBlockRecord'
export const doubleProductBlockRecord = 'DoubleProductBlockRecord'
export const mediaBlockRecord = 'MediaBlockRecord'
export const pixleeFeedRecord = 'PixleeFeedRecord'

export async function transformProductBundles(context, productBundles) {
  await Promise.all(productBundles
    .map(async productBundle => {
      await fetchProductsForFeaturedContentBlockRecords(context.app.$productApiService, productBundle.featuredContent)
      await fetchTotalProductsCountForCategory(context.app.$productApiService, productBundle.category)
    }))
}

async function fetchProductsForFeaturedContentBlockRecords(productApiService, featuredContent) {
  const skus = new Set()

  // Map all unique SKU's
  featuredContent.forEach(item => {
    if (item.__typename === alternativeProductBlockRecord) {
      return skus.add(item.sku,)
    }

    if (item.__typename === doubleProductBlockRecord) {
      skus.add(item.skuLeftProduct)
      skus.add(item.skuRightProduct)
    }
  })

  // Get all products
  const skusArray = Array.from(skus)
  const products = await productApiService.getMultipleProductsBySku(skusArray, { _source: productCardAttributes })

  // Map all products to featured content
  for (let i = 0; i < featuredContent.length; i++) {
    const item = featuredContent[i]

    if (item.__typename === carouselRecordType) {
      item.productCarousel.products = await fetchProductsForCarousel(productApiService, item)
      continue
    }

    if (item.__typename === alternativeProductBlockRecord) {
      item.product = products.find(product => getPrimarySkuFromProduct(product) === item.sku)
      continue
    }

    if (item.__typename === doubleProductBlockRecord) {
      item.leftProduct = products.find(product => getPrimarySkuFromProduct(product) === item.skuLeftProduct)
      item.rightProduct = products.find(product => getPrimarySkuFromProduct(product) === item.skuRightProduct)
    }
  }
}

async function fetchTotalProductsCountForCategory(productApiService, category) {
  try {
    const response = await productApiService.getProductsAndAvailableFiltersByCategoryId(category.id, { hitsSize: 0 })
    category.totalProducts = response.totalProducts
  } catch {
    category.totalProducts = 0
  }
}
